import React from "react";
import {
  Flex,
  Text,
  Link,
  Box,
  Divider,
  Grid,
  GridItem,
} from "@chakra-ui/react";

import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <Flex className={styles.container}>
      <Flex className={styles.upperSection}>
        <img className={styles.logoImg} src="logo_white.png" alt="logo" />
        <ConsultButton />
      </Flex>
      <Divider className={styles.divider} />
      <Grid className={styles.grid}>
        <GridItem className={styles.gridItem} colSpan={1}>
          <div>
            <Text className={styles.gridTitle}>Address</Text>
            <Text className={styles.gridAddress}>
              Suara Merdeka Building 8th Floor
            </Text>
            <Text className={styles.gridAddress}>
              Jl. Pandanaran 30, Semarang
            </Text>
            <Text className={styles.gridAddress}>Central Java, Indonesia</Text>
          </div>
          <Text className={styles.copyright}>© 2024 -- Copyright</Text>
        </GridItem>
        <GridItem className={styles.gridItem} colSpan={1}>
          <div>
            <Text className={styles.gridTitle}>Info</Text>
            <a href="/">
              <Text className={styles.gridText}>Home</Text>
            </a>
            <a href="/about">
              <Text className={styles.gridText}>About</Text>
            </a>
            <a href="/products">
              <Text className={styles.gridText}>Products</Text>
            </a>
          </div>
          <Text className={styles.copyright}>Kuria Komposit Teknologi</Text>
        </GridItem>
        <GridItem className={styles.gridItem} colSpan={1}>
          <div>
            <Text className={styles.gridTitle}>Contact Us</Text>
            <Text className={styles.gridText}>+62 82136055778</Text>
            <Text className={styles.gridText}>info@kuriacomposit.com</Text>
          </div>
        </GridItem>
        <GridItem className={styles.gridItem} colSpan={1}>
          <Flex className={styles.gridSocial}>
            <Link
              href="https://www.instagram.com/kuria.composite/"
              target="_blank"
            >
              <img className={styles.socialIcon} src="instagram.svg" />
            </Link>
            <Link
              href="https://id.linkedin.com/company/kuria-komposit"
              target="_blank"
            >
              <img className={styles.socialIcon} src="linkedin.svg" />
            </Link>
          </Flex>
          <ArrowUpButton />
        </GridItem>
      </Grid>
      <Flex className={styles.copyrightMobile}>
        <Text className={styles.copyrightMobileText}>© 2024 — Copyright</Text>
        <Text className={styles.copyrightMobileText}>
          Kuria Komposit Teknologi
        </Text>
      </Flex>
    </Flex>
  );
};

export default Footer;

function ConsultButton() {
  return (
    <Box
      as="button"
      className={styles.consultButton}
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      _hover={{ bg: "#ebedf0" }}
      _active={{
        bg: "#dddfe2",
        transform: "scale(0.98)",
        borderColor: "#bec3c9",
      }}
      _focus={{
        boxShadow:
          "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
      }}
    >
      <a href="https://wa.me/6282136055778" target="_blank" rel="noreferrer">
        CONSULT NOW
      </a>
    </Box>
  );
}

function ArrowUpButton() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <Box
      as="button"
      className={styles.arrowUpButton}
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      _hover={{ bg: "#ebedf0" }}
      _active={{
        bg: "#dddfe2",
        transform: "scale(0.98)",
        borderColor: "#bec3c9",
      }}
      _focus={{
        boxShadow:
          "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
      }}
      onClick={scrollToTop}
    >
      <img src="ArrowUpVector.svg" className={styles.arrowImg} />
    </Box>
  );
}
