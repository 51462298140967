import { React } from "react";
import { useState } from "react";
import {
  Text,
  Flex,
  Button,
  IconButton,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import styles from "./Comparison.module.css";

export default function Comparison() {
  return (
    <Flex className={styles.compareSection}>
      <Text className={styles.compareTitleText}>
        Kuria Bar <span className={styles.compareSubTitleText}>vs</span> Steel
      </Text>
      <img className={styles.compareImg} src="comparison_product.png" />
      <div className={styles.comparisonBoxContainer}>
        <ComparisonBox
          kuriaText="Tahan terhadap korosi dan bahan kimia"
          title="Corrosion"
          steelText="Rentan terhadap karat dan korosi, terutama di lingkungan yang mengandung garam"
          className={styles.comparisonBox1}
        />
        <ComparisonBox
          kuriaText="1/4 bobot besi sehingga biaya transportasi rendah dan peningkatan efisiensi kerja"
          title="Weight"
          steelText="Berat unit tinggi sehingga biaya transportasi mahal"
        />
        <ComparisonBox
          kuriaText="Kekuatan tarik sekitar 3x lebih kuat dari tulangan baja"
          title="Daya Tarik"
          steelText="Kekuatan tarik rendah dibandingkan dengan material lain"
        />
        <ComparisonBox
          kuriaText="Biaya lebih rendah daripada tulangan baja"
          title="Cost"
          steelText="Harga tulangan baja dapat fluktuasi dan naik"
        />
        <ComparisonBox
          kuriaText="Tidak menghantarkan panas dan listrik"
          title="Conduction"
          steelText="Konduktif listrik dan panas serta tidak cocok untuk bangunan dengan sensitifitas electromagnetic"
        />
      </div>
      <Text className={styles.slideText}>slide {">>>"}</Text>
    </Flex>
  );
}

function ComparisonBox({ kuriaText, title, steelText, className }) {
  return (
    <Flex className={`${styles.comparisonBox} ${className || ""}`}>
      <Text className={styles.comparisonText}>{kuriaText}</Text>
      <Text className={styles.comparisonHeading}>{title}</Text>
      <Text className={styles.comparisonText}>{steelText}</Text>
    </Flex>
  );
}
