import "./App.css";
import "@fontsource/archivo";
import React, { useEffect } from "react";
import NavBar from "./components/NavBar/NavBar";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import CareerPage from "./Pages/CareerPage";
import ContactPage from "./Pages/ContactPage";
import ProductPage from "./Pages/ProductPage";
import theme from "./theme";
import NewsPage from "./Pages/NewsPage";
import ClientPage from "./Pages/ClientPage";
import Layout from "./layout";
import ReactGA from "react-ga4";
import { initGA, logPageView } from "./analytics";
import AboutPage from "./Pages/AboutPage";

function App() {
  useEffect(() => {
    initGA();
    logPageView();
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/products" element={<ProductPage />} />
            <Route path="/careers" element={<CareerPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/client" element={<ClientPage />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
