import React, { useRef } from "react";
import {
  Flex,
  Text,
  Grid,
  GridItem,
  Button,
  Box,
  Divider,
} from "@chakra-ui/react";
import styles from "./AboutPage.module.css";
import Footer from "../components/Footer";

import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function AboutPage() {
  return (
    <Flex className={styles.container}>
      <Flex className={styles.headerSection}>
        <Flex className={styles.leftTextSection}>
          <Text className={styles.headerText}>
            PT. Kuria Composit Teknologi
          </Text>
          <Text className={styles.headerText2}>
            Pioneering Advanced Rebar Solutions
          </Text>
          <img className={styles.headerImgRightMobile} src="header_small.png" />
          <img className={styles.headerImgLeft} src="header_big.png" />
        </Flex>
        <img className={styles.headerImgRight} src="header_small.png" />
      </Flex>

      {/* Values Section  */}
      <Flex className={styles.valuesSection}>
        <Text className={styles.valuesTitle}>Our Values</Text>
        <Flex className={styles.visionMission}>
          <Flex className={styles.visionCard}>
            <Text className={styles.visionTitle}>VISION</Text>
            <Text className={styles.visionText}>
              Menjadi produsen terkemuka komponen komposit berkualitas tinggi
              untuk industri konstruksi.
            </Text>
          </Flex>
          <Flex className={styles.visionCard}>
            <Text className={styles.visionTitle}>MISSION</Text>
            <Text className={styles.visionText}>
              Menyediakan solusi inovatif yang meningkatkan kekuatan, daya
              tahan, dan keberlanjutan struktur bangunan.
            </Text>
          </Flex>
        </Flex>
      </Flex>

      {/* Our Journey  */}
      <Flex className={styles.journeySection}>
        <Text className={styles.journeyTitle}>Our Journey of Innovation</Text>
        <Flex className={styles.journeyContainer}>
          <JourneyCard
            year="2022"
            img="history1.png"
            desc="        Mengenal GFRP Rebar pertama kali, tim melakukan riset produk yang
        mendalam, melihat potensi besarnya untuk menggantikan tulangan baja
        konvensional di Indonesia."
          />
          <Divider opacity={0.5} />
          <JourneyCard
            year="2023"
            img="history2.png"
            desc="Menyediakan solusi inovatif yang meningkatkan kekuatan, daya tahan, dan keberlanjutan struktur bangunan."
          />
          <Divider opacity={0.5} />
          <JourneyCard
            year="2024"
            img="history3.png"
            desc="Kami terus memperluas jangkauan pasar ke berbagai wilayah di Indonesia dan mengembangkan produk-produk baru yang lebih inovatif."
          />
        </Flex>
      </Flex>

      {/* Try Kuria Now Banner  */}
      <Flex className={styles.callOutBanner}>
        <Text className={styles.callOutText}>
          Revolutionize Construction With Us
        </Text>
        <CallOutButton />
      </Flex>

      <Footer />
    </Flex>
  );
}

function JourneyCard({ year, img, desc }) {
  return (
    <Grid className={styles.journeyCard}>
      <Text className={styles.yearText}>{year}</Text>
      <img className={styles.journeyImg} src={img} />
      <Text className={styles.journeyDesc}>{desc}</Text>
    </Grid>
  );
}

function CallOutButton() {
  return (
    <Box
      as="button"
      className={styles.callOutButton}
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      _hover={{ bg: "#ebedf0" }}
      _active={{
        bg: "#dddfe2",
        transform: "scale(0.98)",
        borderColor: "#bec3c9",
      }}
      _focus={{
        boxShadow:
          "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
      }}
    >
      <a href="/contact">
        JOIN OUR TEAM
      </a>
    </Box>
  );
}
